import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic04 from '../assets/images/tank.jpeg'

const Slurry = (props) => (
    <Layout>
        <Helmet>
            <title>Slurry Tank Refurbishment</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        {/* <BannerLanding /> */}

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Slurry Tank Refurbishment</h2>
                    </header>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <div className="image">
                        <img src={pic04} alt="" />
                    </div>
                    <div className="content">
                        <div className="inner">
                            <p>Marcon tanks can completely refurbish damaged slurry tanks or tanks with worn out cylinders.
                            Our services completely remove the damaged cylinder from chassis and replace it with a completely new cylinder, as part of this refurbishment service we fully repaint the new tank with a two part paint allowing our client to have a brand new tanks  for a fraction of the cost of a new tank.</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Slurry